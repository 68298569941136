export const homeLinks = {
  mainSlider: [
    {
      image:
        "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/UpXNabKZetWrevzoH0NL0tZveA0xH4IQyZI8goNt.jpeg",
      link: "/jewellery/category/all-products",
    },
    {
      image:
        "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/lIoBztjflAk9PVVK4dqyycZjYVuBrjhqpPY6vfFR.jpeg",
      link: "/jewellery/category/festive-special-2022",
    },
    {
      image:
        "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/jMGAifKnRGeoccba7jFnCxkWEeaOCJNasCSVN1LT.jpeg",
      link: "/jewellery/category/festive-special-2022",
    },

    {
      image:
        "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/hths9TfzrSALtVz08EUVVCmGtDJipnGUvlmufFjb.jpeg",
      link: "https://mydigigold.com/",
    },

    {
      image:
        "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/QGSQpdyy80QF5X9tsN7vM30kyJU7wphYzVNjus08.jpeg",
      link: "/jewellery/category/everlite-festive-collection",
    },
    {
      image:
        "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/5srew4ZapxDioAd9dbgwTVichiOSqmugFw0iAAXq.jpeg",
      link: "https://www.mygossip.in/",
    },

    {
      image:
        "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/iLIobXtgo3eSqakFq6jeyUbXmmr5YcDjPfBaWERy.jpeg",
      link: "/jewellery/category/customers-favourite",
    },
    {
      image:
        "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/aPgV5c903e8ChoEa1e8gQc2nBcv00iDahoIisM4O.jpeg",
      link: "/jewellery/category/platinum-jewellery",
    },
  ],

  mainMobSlider: [
    {
      image:
        "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/ovFq34rKeo4J6Ej8y09sxxMpoILq5Ymgsdi2XzdW.jpeg",
      link: "/jewellery/category/all-products",
    },
    {
      image:
        "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/kxylEUdgqgz8QgoEZBKlTQD8HHjTGoGJUTqPUrQt.jpeg",
      link: "/jewellery/category/festive-special-2022",
    },
    {
      image:
        "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/4LLViQJpX2FtS1mQQVujhLSR2h0pjq1Prk0XgxW4.jpeg",
      link: "/jewellery/category/festive-special-2022",
    },

    {
      image:
        "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/f1Ozv3XIiPpRVLfE8sFyvMgEBI4g36WD18ZEL97k.jpeg",
      link: "https://mydigigold.com/",
    },

    {
      image:
        "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/meVsWuEffzy87ZXCLgMMJOEoP50gmYkla0hzBubL.jpeg",
      link: "/jewellery/category/everlite-festive-collection",
    },
    {
      image:
        "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/PTue5zywQ2R342Xgcxi3M3kqmbtBseC5GBeNQdSy.jpeg",
      link: "https://www.mygossip.in/",
    },

    {
      image:
        "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/DFI80u3dIbeFHOg2M1UXYHUeEaQVkN2Gehi6tt8R.jpeg",
      link: "/jewellery/category/customers-favourite",
    },

    {
      image:
        "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/xAf7ZvoZJPSY1M0siCescNpY5qCDjz7IRFx5mZvu.jpeg",
      link: "/jewellery/category/platinum-jewellery",
    },
  ],
  // mainSlider: [
  //   {
  //     image:
  //       "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/Bd9gYS2kE1cRlvl95YhPYYFTkbmETBVIFjC2Ttry.jpeg",
  //     link: "/jewellery/category/all-products",
  //   },
  //   {
  //     image:
  //       "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/BeEYgENq6jd9Gk8yKvwPY1BgOOn3UUA8plACjscc.jpeg",
  //     link: "/jewellery/category/festive-special-2022",
  //   },
  //   {
  //     image:
  //       "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/aPgV5c903e8ChoEa1e8gQc2nBcv00iDahoIisM4O.jpeg",
  //     link: "/jewellery/category/platinum-jewellery",
  //   },
  //   {
  //     image:
  //       "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/h804hFvVehnzZsIlxkR1xwCNxFuMH2UcTNS5KGE2.jpeg",
  //     link: "/jewellery/category/festive-special-2022",
  //   },

  //   {
  //     image:
  //       "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/pbHASjujQE3EVqCdjAd2DUtXMjksyBzx7uQHFWta.jpeg",
  //     link: "/jewellery/category/ginkgo-collection",
  //   },
  //   {
  //     image:
  //       "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/odmOsQXx2Jk2HpskYhpgXH4cAmjrb47CX0QpRUdD.jpeg",
  //     link: "/jewellery/category/festive-special-2022",
  //   },

  //   {
  //     image:
  //       "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/iLIobXtgo3eSqakFq6jeyUbXmmr5YcDjPfBaWERy.jpeg",
  //     link: "/jewellery/category/customers-favourite",
  //   },
  //   {
  //     image:
  //       "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/Os3S6yhHjTqviWzyGnHhFXSENC1xTngB8mT6aWCU.jpeg",
  //     link: "/jewellery/category/ginkgo-collection",
  //   },

  //   {
  //     image:
  //       "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/pU0VTrjTbUo0iqqevNnfoJ0UiJIWiiHiZpWYNXT0.jpeg",
  //     link: "https://mydigigold.com/",
  //   },
  //   {
  //     image:
  //       "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/bUXGa90DWqOGUsx0jFXUCqzE3soYlbtay2WZWvXl.jpeg",
  //     link: "/jewellery/category/necklace-gold",
  //   },
  //   {
  //     image:
  //       "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/CvvOJsf457EXmsmStLRB5AquWwVrw6oqU21JXpRA.jpeg",
  //     link: "/jewellery/category/necklace-diamond",
  //   },
  //   {
  //     image:
  //       "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/xxDC2OOuhj92hMUEWvmZ4741XnqwcX4hifWplGsq.jpeg",
  //     link: "/jewellery/category/necklace-diamond",
  //   },
  //   {
  //     image:
  //       "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/HMzLkw3W4OB2tloxWHKGVXo88yFiw8SZ5HaQHkQQ.jpeg",
  //     link: "/jewellery/category/necklace-gold",
  //   },
  // ],

  // mainMobSlider: [
  //   {
  //     image:
  //       "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/wVJcgkYScWcY24KYZ1eS7LBQ93wRxvEWbev5R7vG.jpeg",
  //     link: "/jewellery/category/all-products",
  //   },
  //   {
  //     image:
  //       "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/w83jwoMTnEka14BEn4nIj1gIBqHqJ7jskkGcAiqx.jpeg",
  //     link: "/jewellery/category/festive-special-2022",
  //   },
  //   {
  //     image:
  //       "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/xAf7ZvoZJPSY1M0siCescNpY5qCDjz7IRFx5mZvu.jpeg",
  //     link: "/jewellery/category/platinum-jewellery",
  //   },

  //   {
  //     image:
  //       "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/9kHwBmHD0OhBF0XhWrwgB9OsDIuLfYZJxgoM4wWE.jpeg",
  //     link: "/jewellery/category/festive-special-2022",
  //   },
  //   {
  //     image:
  //       "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/uNFSpcDobWh87AUgFZlQCsmVUgksjBE2ya3ZfUXC.jpeg",
  //     link: "/jewellery/category/ginkgo-collection",
  //   },

  //   {
  //     image:
  //       "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/sLKOM3J6ikXbl2cQhI2zJQApjYM0Td2v5zOLZ79p.jpeg",
  //     link: "/jewellery/category/festive-special-2022",
  //   },
  //   {
  //     image:
  //       "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/DFI80u3dIbeFHOg2M1UXYHUeEaQVkN2Gehi6tt8R.jpeg",
  //     link: "/jewellery/category/customers-favourite",
  //   },
  //   {
  //     image:
  //       "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/ABSBHox83kLmEOgcvJXUDwDPmYRZKjsOQSxTa7Pm.jpeg",
  //     link: "/jewellery/category/ginkgo-collection",
  //   },
  //   {
  //     image:
  //       "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/Yx2mtmectN1pwI5ZI1lg0JPkwZiCa4Qnr52ydmQt.jpeg",
  //     link: "https://mydigigold.com/",
  //   },
  //   {
  //     image:
  //       "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/0BievwkXezN4eE8V2TqJ44aKGrNSpPP77tFha2Ko.jpeg",
  //     link: "/jewellery/category/necklace-gold",
  //   },
  //   {
  //     image:
  //       "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/3uMbKX0VKvhXUIy35yhelNYvsKIXxH1TdBydGnAp.jpeg",
  //     link: "/jewellery/category/necklace-diamond",
  //   },
  //   {
  //     image:
  //       "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/Lkkbf1azaYdOzW1nt2cTUVcgWZn7PdyNdnlQ8nQC.jpeg",
  //     link: "/jewellery/category/necklace-diamond",
  //   },
  //   {
  //     image:
  //       "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/VZIbh2oxcFtaBy5CfDhUHokUaBsNKHTOFipYWNuQ.jpeg",
  //     link: "/jewellery/category/necklace-gold",
  //   },
  // ],

  shopByCategory: {
    title: "SHOP JEWELLERY BY CATEGORY",
    subTitle: "",
    sliders: [
      {
        image: process.env.NEXT_PUBLIC_LOCAL_IMAGE_URL + "/img/category/er.jpg",
        link: "/jewellery/category/earrings-all-products",
        name: "EARRINGS",
        alt: "Shop earrings by Senco Gold & Diamonds",
      },
      {
        image:
          process.env.NEXT_PUBLIC_LOCAL_IMAGE_URL + "/img/category/assets3.jpg",
        link: "/jewellery/category/ring-all-products",
        name: "RINGS",
        alt: "Shop rings by Senco Gold & Diamonds",
      },
      {
        image:
          process.env.NEXT_PUBLIC_LOCAL_IMAGE_URL + "/img/category/assets2.jpg",
        link: "/jewellery/category/bracelet-all-products",
        name: "BRACELETS",
        alt: "Shop bracelets by Senco Gold & Diamonds",
      },
      {
        image:
          process.env.NEXT_PUBLIC_LOCAL_IMAGE_URL + "/img/category/assets1.jpg",
        link: "/jewellery/category/chain-all-products",
        name: "CHAINS",
        alt: "Shop chains by Senco Gold & Diamonds",
      },

      {
        image:
          process.env.NEXT_PUBLIC_LOCAL_IMAGE_URL + "/img/category/assets4.jpg",
        link: "/jewellery/category/necklace-all-products",
        name: "NECKLACES",
        alt: "Shop necklaces by Senco Gold & Diamonds",
      },

      {
        image:
          process.env.NEXT_PUBLIC_LOCAL_IMAGE_URL + "/img/category/assets6.jpg",
        // "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/HMxk4OYmEF9oxx1hdWpdYmLqV2MrWXrhBQaXk1h7.jpeg",
        link: "/jewellery/category/coins-and-bars",
        name: "COINS & BARS",
        alt: "Shop coins and bars by Senco Gold & Diamonds",
      },
    ],
  },

  shopByBudget: {
    title: "SHOP JEWELLERY BY BUDGET",
    subTitle: "",
    images: [
      {
        image:
          "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/TOty8xcb4TeWXAXfWaxhpXHfi3sUtbS3PefxUJhZ.jpeg",
        link: "/jewellery/category/all-products?priceMax=10000",
        price: "Under 10K",
        alt: "Shop jewellery under 10k by Senco Gold & Diamonds",
      },
      {
        image:
          "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/c9YlCmY15vdAKuUMGMF1wJZN6O229KaaYeXGnf30.jpeg",
        link: "/jewellery/category/all-products?priceMin=10000&priceMax=25000",
        price: "10K - 25K",
        alt: "Shop jewellery from 10k-25k by Senco Gold & Diamonds",
      },
      {
        image:
          "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/xe1vJ1wq4Xb0CvTrjeEMn5JJrgIb0yxjtCodz0Eb.jpeg",
        link: "/jewellery/category/all-products?priceMin=25000&priceMax=50000",
        price: "25K - 50K",
        alt: "Shop jewellery from 25k-50k by Senco Gold & Diamonds",
      },
      {
        image:
          "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/r6mWAPI7mOlfH3bz7JuqnHf2G0vfDQiXMXe1BFO8.jpeg",
        link: "/jewellery/category/all-products?priceMin=50000",
        price: "50K Above",
        alt: "Shop jewellery above 50k by Senco Gold & Diamonds",
      },
    ],
  },
  collection: {
    title: "OUR JEWELLERY DESIGN COLLECTIONS",
    subTitle: "",
    sliders: [
      {
        image:
          "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/fTjqTuZoMfqV6EVHqUM5mo0CloUksEXoxtWlHxGs.jpeg",
        link: "/jewellery/category/aham-collection",
        name: "Aham",
        alt: "Shop men’s jewellery by Senco Gold and Diamonds",
      },
      {
        image:
          "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/LSP3suEZFAc1qQbfMYAYww0P9xwhzfzBny9B2QU4.jpeg",
        link: "/jewellery/category/kids-collection",
        name: "Kids",
        alt: "Shop kids’ jewellery by Senco Gold and Diamonds",
      },
      {
        image:
          "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/M0Pbq1n1Zh49TsN6GDmIaBykg8twdXo2g2Y1oiYl.jpeg",
        link: "/jewellery/category/polki-collection",
        name: "Polki",
        alt: "Shop Polki jewellery by Senco Gold and Diamonds",
      },
      {
        image:
          "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/TotTL0wVdQr9zyfw4R8G13Y84Kt6VLBVPLvWZ4Wu.jpeg",
        link: "/jewellery/category/vivaha-collection",
        name: "Vivaah",
        alt: "hop wedding jewellery by Senco Gold and Diamonds",
      },
      {
        image:
          "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/mWCdendaGLV6ryNiH3gXMq7uHzPUDBkMwDCrnxMQ.jpeg",
        link: "/jewellery/category/necklace-diamond",
        name: "Diamond Necklace",
        alt: "Shop diamond necklaces by Senco Gold and Diamonds",
      },
      {
        image:
          "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/0iSLjHikwsFRhLHhdgOo1DpPJgKoiBnk71D118BM.jpeg",
        link: "/jewellery/category/telegu-wedding",
        name: "Antique",
        alt: "Shop antique gold jewellery by Senco Gold and Diamonds",
      },
      {
        image:
          "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/MxEpn6mCWMujI7tT8pXbfzrKkpAWuOWGVDATveFq.jpeg",
        link: "/jewellery/category/earring-diamond",
        name: "Diamond Earring",
        alt: "Shop diamond earrings by Senco Gold and Diamonds",
      },
      {
        image:
          "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/n5qhKKDZcRDT1hA6q0EUdx1HGh605NfqDtCtUwpj.jpeg",
        link: "/jewellery/category/kannada-wedding",
        name: "Temple",
        alt: "Shop temple jewellery by Senco Gold and Diamonds",
      },
      {
        image:
          "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/TALduwSh5dniE52WJtP4jCRwX4MQiUYIYKekpCuj.jpeg",
        link: "/jewellery/category/ring-diamond",
        name: "Diamond Ring",
        alt: "Shop diamond rings by Senco Gold and Diamonds",
      },
    ],
  },
  lustCollection: {
    youtube_iframe:
      '<iframe width="560" height="315" src="https://www.youtube.com/embed/N_Qr_jx1M4E?si=BqxO56CLGsXTUv7Z" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
    link: "/all-collections",
  },
  perfectGift: {
    title: "A PERFECT GIFT",
    subTitle: "",
    link: "https://sencogoldanddiamonds.com/gifts/",
    images: [
      {
        name: "Engagement",
        image:
          "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/vmyCnurcYcdxRPQcEIKevNhXJj7WkBy0OWinM2Qx.jpeg",
        link: "/jewellery/category/perfect-love-collection",
      },
      {
        name: "Birthday",
        image:
          "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/RpbVFNMG8syAU1gZTdGzpUVtFJKqNQfz456s7dqL.jpeg",
        link: "/jewellery/category/all-products?priceMax=30000",
      },
      {
        name: "Wedding",
        image:
          "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/IbKJS4ycFk6j18AzA0dbkuTNYZRDnGL63e9dDlHO.jpeg",

        link: "/jewellery/category/vivaha-collection",
      },
      {
        name: "Small Wonders",
        image:
          "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/mCdmvmw50YGwnfkTAbd9Ehied8aMYUNH0z3Wz1W8.jpeg",
        link: "/jewellery/category/kids-collection",
      },
      {
        name: "Anniversary",
        image:
          "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/gHd6SCA5jg2VSJBRIxlE323tRE15i06Tcmd4fRgb.jpeg",
        link: "/jewellery/category/vivaha-collection?priceMax=75000",
      },
    ],
  },
  newArival: {
    title: "NEW ARRIVALS",
    subTitle: "",
    link: "/jewellery/category/all-products",
    images: [
      {
        name: "Vday",
        image: process.env.NEXT_PUBLIC_LOCAL_IMAGE_URL + "/img/features-12.png",
        link: "/jewellery/category/chain-gold",
      },
      {
        name: "Birthday",
        image: process.env.NEXT_PUBLIC_LOCAL_IMAGE_URL + "/img/features-13.png",
        link: "/jewellery/category/pendant-gold",
      },
      {
        name: "Anniversary",
        image: process.env.NEXT_PUBLIC_LOCAL_IMAGE_URL + "/img/features-10.png",
        link: "/jewellery/category/necklace-gold",
      },
      {
        name: "Vday",
        image: process.env.NEXT_PUBLIC_LOCAL_IMAGE_URL + "/img/features-11.png",
        link: "/jewellery/category/bangle-gold",
      },
    ],
  },
  menCollection: {
    title: "AHAM – MEN’S JEWELLERY COLLECTION",
    subTitle: "",
    link: "/jewellery/category/aham-collection",
    images: [
      {
        name: "Ring",
        staringPrice: "8000",
        image:
          "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/YXbJtJY4nkfSByEuLifNMXmJnfJk0lUuFsMlYwmy.jpeg",
        link: "/jewellery/category/ring-aham-collection",
      },
      {
        name: "Pendant",
        staringPrice: "8000",
        image:
          "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/P96UC2bJj1tzwoStGzQG0yaTVh809Mxav7onSZr8.jpeg",
        link: "/jewellery/category/pendant-aham-collection",
      },
      {
        name: "Tie Pin",
        staringPrice: "8000",
        image:
          "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/4mXnI5RmIGpBHvmZiRbKvPEhAzLi2xQfnRTn0cEW.jpeg",
        link: "/jewellery/category/accessories-aham-collection",
      },
      {
        name: "Wristlet",
        staringPrice: "8000",
        image:
          "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/JSK1zKS94osgW758NmEy9TcJFtToWugckNvVO7tC.jpeg",
        link: "/jewellery/category/wristlet-aham-collection",
      },
      {
        name: "Chain",
        staringPrice: "8000",
        image:
          "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/bNWa7xt911bchd6bPEtkj8HqsOEqYvDtOLtxGG8I.jpeg",
        link: "/jewellery/category/chain-aham-collection",
      },
    ],
  },

  sencoExclusive: {
    title: "SENCO EXCLUSIVE",
    subTitle: "",
    link: "/senco-exclusive",
    images: [
      {
        name: "Gossip",
        image:
          "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/spPPpwWC4UUNEFbbWbj96ASEeL0lh1RPxPtt42dR.jpeg",
        link: "https://www.mygossip.in/",
      },
      {
        name: "Kiara Collections",
        image:
          "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/TfKAnyyxt1JRfdVBjCiHjdYs87eaEmhYw7cqDVld.jpeg",
        link: "/jewellery/category/vivaha-collection-2022",
      },
      {
        name: "Platinum Rings",
        image:
          "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/ckFbwcuK33ztpcrr8SOv6c3Fq7yV1UaaI383Igua.jpeg",
        link: "/jewellery/category/platinum-ring",
      },
      {
        name: "Silver Utensils",
        image:
          "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/xfEPCOJemaKFyOCU0iK32iRRZffT00puQANDsf9L.jpeg",
        link: "/jewellery/category/silver-utensils",
      },
      {
        name: "Ginkgo Collections",
        image:
          "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/xJ0ZxqJ3MmqFzrzCdoutcIcDxqUrPO71ezKmbAZQ.jpeg",
        link: "/jewellery/category/ginkgo-collection",
      },
      {
        name: "Astro",
        image:
          "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/oqRKWGDrdAH7oczP8u6m8OOSsKulsx28h4s47pjy.jpeg",
        link: "/astro",
      },
    ],
  },
  bestSeller: {
    title: "CUSTOMERS FAVOURITE",
    link: "https://sencogoldanddiamonds.com/",
    images: [
      {
        price: "12000",
        image:
          process.env.NEXT_PUBLIC_LOCAL_IMAGE_URL + "/img/bestseller/b1.jpg",
        link: "https://sencogoldanddiamonds.com/",
      },
      {
        price: "12000",
        image:
          process.env.NEXT_PUBLIC_LOCAL_IMAGE_URL + "/img/bestseller/b2.jpg",
        link: "https://sencogoldanddiamonds.com/",
      },
      {
        price: "12000",
        image:
          process.env.NEXT_PUBLIC_LOCAL_IMAGE_URL + "/img/bestseller/b3.jpg",
        link: "https://sencogoldanddiamonds.com/",
      },
      {
        price: "12000",
        image:
          process.env.NEXT_PUBLIC_LOCAL_IMAGE_URL + "/img/bestseller/b4.jpg",
        link: "https://sencogoldanddiamonds.com/",
      },
    ],
  },
  trendingDesign: {
    title: "RECENTLY VIEWED",
    link: "https://sencogoldanddiamonds.com/",
    images: [
      {
        image: process.env.NEXT_PUBLIC_LOCAL_IMAGE_URL + "/img/features-12.png",
        link: "https://sencogoldanddiamonds.com/",
      },
      {
        image: process.env.NEXT_PUBLIC_LOCAL_IMAGE_URL + "/img/features-13.png",
        link: "https://sencogoldanddiamonds.com/",
      },
      {
        image: process.env.NEXT_PUBLIC_LOCAL_IMAGE_URL + "/img/features-10.png",
        link: "https://sencogoldanddiamonds.com/",
      },
      {
        image: process.env.NEXT_PUBLIC_LOCAL_IMAGE_URL + "/img/features-11.png",
        link: "https://sencogoldanddiamonds.com/",
      },
    ],
  },
  vivahCollection: {
    title: "VIVAAH – WEDDING JEWELLERY COLLECTION",
    subTitle: "",
    youtube_iframe_id: "rLO-BfKp80I",
    link: "/jewellery/category/vivaha-collection",
    images: [
      {
        image:
          "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/6EXKElQQhXVlfpI3esepZiqW0N7Bctpp6MerPdff.jpeg",
        link: "/jewellery/category/bengali-wedding",
        alt: "Shop Bengali wedding jewellery by Senco Gold and Diamonds",
      },
      {
        image:
          "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/ppn4ZlPYW5ArnaQ2u7kJ0lS8giQ41aqwz3eV8i33.jpeg",
        link: "/jewellery/category/necklace-diamond",
        alt: "Shop diamond wedding jewellery by Senco Gold and Diamonds",
      },
      {
        image:
          "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/yBxh3FPrgWT2WVTMbfKlawVkN2NxIWl7OrDoAOPv.jpeg",
        link: "/jewellery/category/north-indian-wedding",
        alt: "Shop North Indian wedding jewellery by Senco Gold and Diamonds",
      },
      {
        image:
          "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/iXl8TufRYT7k4VjKHYMLOnjlNGSOKIC1IjzJLkyG.jpeg",
        link: "/jewellery/category/kannada-wedding",
        alt: "Shop Kannada wedding jewellery by Senco Gold and Diamonds",
      },
      {
        image:
          "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/aLsmETdsyEqoathCIqagqw1xAay1apos0SXnfEhG.jpeg",
        link: "/jewellery/category/muslim-wedding",
        alt: "Shop Muslim wedding jewellery by Senco Gold and Diamonds",
      },
      {
        image:
          "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/lwL0qbn0RpNJlSsPAXmDgrk3IWMQyk5aScKwfnFF.jpeg",
        link: "/jewellery/category/bihari-wedding",
        alt: "Shop Bihari wedding jewellery by Senco Gold and Diamonds",
      },
    ],
  },
  customerSpeaks: {
    title: "CUSTOMER SPEAKS",
    subTitle: "",
    comments: [
      {
        customerImage:
          process.env.NEXT_PUBLIC_LOCAL_IMAGE_URL + "/img/features-19.png",
        productImage:
          process.env.NEXT_PUBLIC_LOCAL_IMAGE_URL + "/img/features-13.png",
        customerComment:
          " <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p> <p> <span> <b>Monica Bose</b>, Kolkata </span> </p>",
        productDesc:
          "Contrary to popular belief, Lorem Ipsum is not simply random text",
      },
      {
        customerImage:
          process.env.NEXT_PUBLIC_LOCAL_IMAGE_URL + "/img/features-19.png",
        productImage:
          process.env.NEXT_PUBLIC_LOCAL_IMAGE_URL + "/img/features-13.png",
        customerComment:
          " <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p> <p> <span> <b>Monica Bose</b>, Kolkata </span> </p>",
        productDesc:
          "Contrary to popular belief, Lorem Ipsum is not simply random text",
      },
      {
        customerImage:
          process.env.NEXT_PUBLIC_LOCAL_IMAGE_URL + "/img/features-19.png",
        productImage:
          process.env.NEXT_PUBLIC_LOCAL_IMAGE_URL + "/img/features-13.png",
        customerComment:
          " <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p> <p> <span> <b>Monica Bose</b>, Kolkata </span></p>",
        productDesc:
          "Contrary to popular belief, Lorem Ipsum is not simply random text",
      },
      {
        customerImage:
          process.env.NEXT_PUBLIC_LOCAL_IMAGE_URL + "/img/features-19.png",
        productImage:
          process.env.NEXT_PUBLIC_LOCAL_IMAGE_URL + "/img/features-13.png",
        customerComment:
          " <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p> <p> <span> <b>Monica Bose</b>, Kolkata </span> </p>",
        productDesc:
          "Contrary to popular belief, Lorem Ipsum is not simply random text",
      },
      {
        customerImage:
          process.env.NEXT_PUBLIC_LOCAL_IMAGE_URL + "/img/features-19.png",
        productImage:
          process.env.NEXT_PUBLIC_LOCAL_IMAGE_URL + "/img/features-13.png",
        customerComment:
          " <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p> <p> <span> <b>Monica Bose</b>, Kolkata </span> </p>",
        productDesc:
          "Contrary to popular belief, Lorem Ipsum is not simply random text",
      },
    ],
  },
  news: {
    title: "The News",
    subTitle: "",
    link: "#",
    news: [
      {
        image: process.env.NEXT_PUBLIC_LOCAL_IMAGE_URL + "/img/3.png",
        html: " <span>The Blog</span> <h3>7 Jwellery <br /> Trends to <br /> Watch For <br />in Festive<br /> Season </h3> <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and crambled it to make a type specimen book.</p>",
        link: "#",
      },
      {
        image: process.env.NEXT_PUBLIC_LOCAL_IMAGE_URL + "/img/4.png",
        link: "#",
        html: "<span>The Blog</span> <h3 className= Senco <br /> Announced  <br /> <span>Dutee Chand</span> <br /> As their <br /> Brand <br /> Ambassador </h3>",
      },
      {
        image: process.env.NEXT_PUBLIC_LOCAL_IMAGE_URL + "/img/3.png",
        link: "#",
        html: "<span>The Blog</span> <h3>7 Jwellery <br /> Trends to <br /> Watch For <br />in Festive<br /> Season </h3> <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and crambled it to make a type specimen book.</p>",
      },
    ],
  },
  store: {
    title: "150 + STORES ACROSS THE NATION",
    subTitle: "",
    link: "/our-stores",
    image:
      "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/i48Ink8A1WuxogKINt8R25eBp3VPGb5J2njH2qAZ.jpeg",
    story:
      " <p> Senco Gold and Diamonds is brand owned by Senco Gold Limited (‘the Company’) having a legacy of over eight decades. Our company was originally incorporated as Senco Gold Private Limited on August 22, 1994, at Kolkata, West Bengal, under the provisions of the Companies Act, 1956. </p> <p> Later, in order to expand its activities in the field of jewellery business, the Company was converted to a public limited company in August 2007 with the name Senco Gold Limited. </p> <p> Subsequently, pursuant to a resolution passed at a meeting of the Board of Directors held on June 27, 2007, and a special resolution of the shareholders at the EGM held on August 8, 2007, the name of the company was changed to Senco Gold Limited, resulting in its conversion into a public limited company. A fresh certificate of incorporation dated August 31, 2007, consequent to the change of name, was issued by the Registrar of Companies, West Bengal. The Company was converted to a public limited company in order to expand its activities in the field of jewellery business. </p>",
    story_link: "/about-us",
  },
  freaturedCustomer: {
    title: "YOU X SENCO",
    subTitle: "",
    images: [
      {
        image:
          "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/u6miA6PXOwr4Ub2wXFmT1Fo44FE10cFQO7IgPLXR.jpeg",
        link: "https://www.instagram.com/jaya.ahsan/",
      },
      {
        image:
          "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/eH7fNSjhtD7SrhAQEGPxbbhYW4YA5FOaQD3Lc63n.jpeg",
        link: "https://www.instagram.com/ishaasaha_official/",
      },
      {
        image:
          "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/KqP7WFSTZmXeHPAQyWNdrEuMjPIkeeXws8SpLt4S.jpeg",
        link: "https://www.instagram.com/madhumita_sarcar/",
      },
      // {
      //   image:
      //     "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/piibuyeGvJmFYEri4ui4IrGJVoRnvqyhSr80pCBf.jpeg",
      //   link: "https://www.instagram.com/souravganguly/",
      // },
      {
        image:
          "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/I6Eg9lUgdtXdLduVBW1JndHRrq5pojezwjoju5ge.jpeg",
        link: "https://www.instagram.com/sunitakaushik5/",
      },
      {
        image:
          "https://sencowebfiles.s3.ap-south-1.amazonaws.com/website/files/fn7861lzS9Hb94dJeiq3rPbe7E9vczeFQPLimQoa.jpeg",
        link: "https://www.instagram.com/balanvidya/",
      },
    ],
    footer: {
      desc: "Minim veniam, quis nostrud exercitation ullamco laboris nisi win a diamond ring",
      link: "/jewellery/category/ring-diamond",
      linkdesc: "win a diamond ring",
    },
  },
};
