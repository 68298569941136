import { retryEvent } from "@/utils/retryEvent";

export const unbxdTrackMultipleEvent = (name: string, data: any) => {
  retryEvent(
    `unbxdTrackMultiple: ${name}`,
    () => window.Unbxd && typeof window.Unbxd.track === "function",
    () => window.Unbxd.trackMultiple(name, data)
  );
};

export const unbxdTrackEvent = (name: string, data: any) => {
  retryEvent(
    `unbxdTrack: ${name}`,
    () => window.Unbxd && typeof window.Unbxd.track === "function",
    () => window.Unbxd.track(name, data)
  );
};
